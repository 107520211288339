<template>
  <div>
    <v-container>
      <v-card-title>
        <v-breadcrumbs class="text-uppercase font-weight-bolder" :items="items" />

        <v-spacer />
      </v-card-title>

      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="12" md="6">
            <v-row class="text-justify">
              <v-col cols="12" v-if="!isWeb">
                <v-card-text class="justify-center text-center">
                  <v-avatar rounded size="300">
                    <img src="@/assets/images/MVO.png" alt="alt">
                  </v-avatar>
                </v-card-text>
              </v-col>
              <v-col cols="12">
                <b>
                  Bem-vindo à Mutue Loja Online: O Seu Destino de Compras em um
                  Clique!
                </b>
                <br>
                <p>
                  Na era da conveniência digital, a Mutue Loja Online está à sua
                  disposição, pronta para transformar a maneira como você faz
                  compras online. Imagine um mundo onde a busca pelo produto
                  perfeito, a facilidade de compra e a satisfação do cliente se
                  unem em um só lugar. Bem-vindo a esse mundo - bem-vindo à
                  Mutue!
                </p>

                <b> A Revolução da Compra Online </b>

                <p>
                  Na Mutue, acreditamos que a experiência de compra online não
                  deve ser apenas uma tarefa, mas uma verdadeira aventura!
                  Navegue por nosso vasto catálogo com uma diversidade que
                  atende a todos os gostos e necessidades.
                </p>

                <b> Diversidade Deslumbrante </b>

                <p>
                  Nossa loja é um oásis de diversidade, abrigando produtos de
                  qualidade que refletem as últimas tendências e estilos.
                  Desfrute de uma variedade infinita de marcas e produtos de
                  alta qualidade a preços acessíveis. A Mutue é o lugar onde a
                  elegância e a economia se encontram.
                </p>

                <b> Experiência do Cliente em Primeiro Lugar </b>

                <p>
                  Na Mutue, a satisfação do cliente é nossa prioridade número
                  um. Oferecemos uma experiência de compra online intuitiva, com
                  navegação simplificada e informações detalhadas sobre os
                  produtos para ajudar você a tomar DECISÕES ACERTIVAS. Além
                  disso, nossa equipe de atendimento ao cliente está à
                  disposição para ajudar em todas as etapas do processo.
                </p>

                <b> Segurança e Confiabilidade </b>

                <p>
                  Sabemos que a segurança online é uma preocupação para todos.
                  Na Mutue, investimos em tecnologia de ponta para garantir que
                  suas informações pessoais e financeiras estejam sempre
                  protegidas. Sua tranquilidade é uma das nossas missões.
                </p>

                <b> Entrega Confiável </b>

                <p>
                  Quando você faz um pedido na Mutue, pode confiar que ele
                  chegará até você de forma rápida e segura. Oferecemos opções
                  de entrega flexíveis para se adequar ao seu estilo de vida.
                </p>

                <b> Faça Parte da Família Mutue </b>
                <p>
                  Junte-se a milhares de clientes satisfeitos que já descobriram
                  a Mutue Loja Online. Explore, compre e desfrute da revolução
                  do varejo digital com a gente! Visite-nos hoje mesmo em
                  www.loja.mutue.net e faça parte da nossa comunidade de
                  compradores felizes. Encontre qualidade, economia e
                  conveniência em um só lugar. Mutue Loja Online: Transformando
                  sua experiência de compra online.
                </p>
              </v-col>
            </v-row>
          </v-col>
          <!-- class="hidden-md-and-up" -->
          <v-col cols="12" md="6" sm="12" v-if="isWeb">
            <v-card-text class="justify-center text-center">
              <v-avatar rounded="" size="600">
                <img src="@/assets/images/MVO.png" alt="alt">
              </v-avatar>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
  import CardCart from '@/components/cartCard/CardCart'
  export default {
    components: {
      CardCart,
    },
     metaInfo() {
    return {
      title: 'Quem Somos - Mutue Loja Online',
      meta: [
        {
          name: 'Quem Somos',
          content: 'A Mutue Loja Online está à sua disposição, pronta para transformar a maneira como você faz compras online. Imagine um mundo onde a busca pelo produto perfeito, a facilidade de compra e a satisfação do cliente se unem em um só lugar. Bem-vindo a esse mundo - bem-vindo à Mutue!',
        },
    ],
    };
  },
    data () {
      return {
        isWeb:false,
        items: [
          {
            text: 'Início',
            disabled: false,
            href: '/',
          },
          {
            text: 'Quem Somos',
            disabled: true,
            href: '/',
          },
        ],

        tab: null,
        page: 1,
        itemsPerPage: 8,
      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
    this.isWeb = this.$vuetify.breakpoint.name === 'xl' || this.$vuetify.breakpoint.name==='lg' || this.$vuetify.breakpoint.name==='md' ;
 
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
    },
  }
</script>

<style lang="scss" scoped>
.vendor-card {
  .vendor-avatar {
    position: absolute;
    top: -28px;
    left: 20px;

    img {
      border: 4px solid #fff;
    }
  }

  .vendor-btn {
    position: absolute;
    bottom: 6px;
  }
}
</style>
